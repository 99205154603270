import { replace } from 'connected-react-router';
import ApiManager from 'utils/ApiManager';
import snackbarMessages from 'utils/snackbarMessages';
import _first from 'lodash/first';
import _get from 'lodash/get';

import {
  hideLoader,
  showLoader,
  hideDrawer,
  showSnackbar,
  showTransparentLoader,
} from 'containers/store';

import messages from 'components/pages/AnonymousUserData/AnonymousUserData.messages';

export const initialState = {
  isLoadedPage: true,
  initialValues: {
    gender: null,
    year: '',
    month: '',
  },
};

export const actionTypes = {
  LOAD_PAGE_SUCCESS: 'ANONYMOUS_USER_DATA/LOAD_PAGE_SUCCESS',
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_PAGE_SUCCESS: {
      return {
        ...state,
        isLoadedPage: true,
      };
    }

    default:
      return state;
  }
};

const loadPageSuccess = (responses) => ({
  type: actionTypes.LOAD_PAGE_SUCCESS,
  responses,
});

const checkCriteria = (id, patientId, sex, birthDate) => (dispatch) => ApiManager.request(
  'get', dispatch, `session_patient_groups/${id}/patients/${patientId}/matching_criteria?sex=${sex}&birthDate=${birthDate}`,
);

const saveData = (id, body) => (dispatch) => ApiManager.request('put', dispatch, `patients/${id}`, body);

export const onSubmit = (values, methods, intl) => async (dispatch, getStore) => {
  const patientId = getStore().Global.sessionPatient.patient.id;
  const id = _get(_first(getStore().Global.groupSurveys), 'session_patient_group_id', null);
  const sex = values.gender === 'male';
  const birthDate = `${values.year}-${values.month}-01`;

  dispatch(showTransparentLoader());

  try {
    await dispatch(checkCriteria(id, patientId, sex, birthDate));
  } catch (error) {
    if (!_get(error, 'error.success', false)) {
      dispatch(hideLoader());
      methods.setFieldError('year', intl.formatMessage(messages.yearNotMatching));
      methods.setFieldError('month', intl.formatMessage(messages.monthNotMatching));
    }

    return;
  }
  const requestBody = {
    data: {
      sex,
      birth_date: birthDate,
    },
  };

  dispatch(saveData(patientId, requestBody)).then(() => {
    dispatch(hideLoader());
    dispatch(replace('/researchStart'));
  }).catch(() => {
    dispatch(hideLoader());
    dispatch(showSnackbar(snackbarMessages.globalError));
  });
};

export const loadPageData = () => (dispatch) => {
  dispatch(hideDrawer());
  dispatch(showLoader());
  dispatch(loadPageSuccess());
  dispatch(hideLoader());
};

import React from 'react';
import ReactDOM from 'react-dom';
import { IntlProvider } from 'react-intl';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { ConnectedRouter } from 'connected-react-router';
import { Provider, connect } from 'react-redux';

import { createBrowserHistory as createHistory } from 'history';
import theme from 'theme/theme';
import Routes from 'containers/routes';
import initStore from 'utils/initStore';
import getHistory from 'utils/getHistory';
import VersionManager from 'utils/VersionManager';
import 'utils/polyfills';
import * as serviceWorker from './serviceWorker';

const browserHistory = createHistory();
const store = initStore(browserHistory);
const history = getHistory(browserHistory, store);

class App extends React.PureComponent {
  async componentDidMount() {
    if (typeof Sentry !== 'undefined') {
      Sentry.init({ dsn: 'https://8395faeebbc8472fac6f25d334ee0e5e@sentry.fingoweb.com/18' });
    }

    await VersionManager.init();
    VersionManager.startTimeout();
  }

  componentWillUnmount() {
    VersionManager.resetTimeout();
  }

  render() {
    return (
      <IntlProvider locale="pl" defaultLocale="pl">
        <MuiThemeProvider theme={theme}>
          <ConnectedRouter history={history}>
            <Routes history={history} />
          </ConnectedRouter>
        </MuiThemeProvider>
      </IntlProvider>
    );
  }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = {};

const AppConnected = connect(mapStateToProps, mapDispatchToProps)(App);

ReactDOM.render(
  <Provider store={store}>
    <AppConnected />
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

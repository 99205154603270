import { defineMessages } from 'react-intl';

export default defineMessages({
  chooseGender: 'Wybierz płeć',
  insertBirthData: 'Podaj rok i miesiąc urodzenia',
  year: 'Rok',
  month: 'Miesiąc',
  next: 'Dalej',
  yearNotMatching: 'Rok poza zakresem standaryzacji. Zawołaj osobę prowadzącą badanie.',
  monthNotMatching: 'Miesiąc poza zakresem standaryzacji. Zawołaj osobę prowadzącą badanie.',
  january: 'Styczeń',
  february: 'Luty',
  march: 'Marzec',
  april: 'Kwiecień',
  may: 'Maj',
  june: 'Czerwiec',
  july: 'Lipiec',
  august: 'Sierpień',
  september: 'Wrzesień',
  october: 'Październik',
  november: 'Listopad',
  december: 'Grudzień',
});

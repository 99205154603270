import _find from 'lodash/find';
import { push } from 'connected-react-router';

import snackbarMessages from 'utils/snackbarMessages';
import isBadRequest from 'utils/isBadRequest';

import {
  hideLoader,
  showTransparentLoader,
  showLoader,
  hideDrawer,
  setSessionPatient,
  onSessionStatusChange,
  setGroupSurveysData,
  showSnackbar,
} from 'containers/store';

import { getSessionPatient, getPatientGroupSurveys } from 'containers/Login/store';
import { clearQuestionsAndIndex } from 'containers/QuestionPage/store';

export const initialState = {
  isLoadedPage: false,
};

export const actionTypes = {
  LOAD_PAGE_SUCCESS: 'GROUP_SURVEY_GAP/LOAD_PAGE_SUCCESS',
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_PAGE_SUCCESS: {
      return {
        ...state,
        isLoadedPage: true,
      };
    }

    default:
      return state;
  }
};

const loadPageSuccess = () => ({
  type: actionTypes.LOAD_PAGE_SUCCESS,
});

export const onSubmit = () => async (dispatch, getStore) => {
  dispatch(showTransparentLoader());

  const { sessionPatient, groupSurveys } = getStore().Global;

  const nextSession = _find(groupSurveys, (survey) => survey.status === 'CREATED');

  const sessionData = await dispatch(getSessionPatient(nextSession.id));
  const currentQuestionsCounter = sessionData.data.current_questions_counter;

  dispatch(setSessionPatient({
    ...sessionPatient,
    id: nextSession.id,
    current_questions_counter: currentQuestionsCounter,
  }));

  dispatch(onSessionStatusChange('STARTED'))
    .then(() => {
      dispatch(clearQuestionsAndIndex());
      dispatch(push('/questionPage', { patientSession: true, fromStartPage: true }));
      dispatch(hideLoader());
    }).catch((error) => {
      if (isBadRequest(error)) {
        dispatch(showSnackbar(snackbarMessages.wrongData));
      } else {
        dispatch(showSnackbar(snackbarMessages.globalError));
      }

      dispatch(hideLoader());
    });
};

export const loadPageData = () => (dispatch, getStore) => {
  const { sessionPatient } = getStore().Global;
  dispatch(hideDrawer());
  dispatch(showLoader());
  dispatch(getPatientGroupSurveys(sessionPatient.code)).then((res) => {
    dispatch(setGroupSurveysData(res.data));
    dispatch(loadPageSuccess());
  }).catch((error) => {
    if (isBadRequest(error)) {
      dispatch(showSnackbar(snackbarMessages.wrongData));
    } else {
      dispatch(showSnackbar(snackbarMessages.globalError));
    }

    dispatch(hideLoader());
  });
};
